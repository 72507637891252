import React, { useState, useEffect } from "react";
import Header from "../../context/Header";
import app from "../../context/base.js";
import { useHistory } from "react-router-dom";
import { Card, Button, Form, Spinner } from "react-bootstrap";
import { loadStripe } from "@stripe/stripe-js";


import {
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import axios from "axios";

const stripePromise = loadStripe(
  "pk_test_51H3LYlElUGTUUmSH5tPbaECJFVPBtw5eq6UoQCCdcPJhQq1dZkKOTyEs1d8DevXxTs4KHjoI9DP5srq4cmqOYgFZ00wgqE3on8"
);

const FEE = 200;

const MyCheckoutForm = ({ component: RouteComponent, ...rest }) => {
  const [passengerData, setPassengerData] = useState(null);
  const [cardholderName, setCardholderName] = useState("");
  const [numPassengers, setNumPassengers] = useState(1);
  const [totalFee, setTotalFee] = useState(1);
  const history = useHistory();

  const [showSpinner, setShowSpinner] = useState(false);

  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    setPassengerData(history.location.state.detail);
    setNumPassengers(history.location.state.detail.numPassengers);
    setTotalFee(numPassengers * FEE);
  }, [passengerData, totalFee, numPassengers, history.location.state.detail]);

  function validateSubmit() {
    return true;
  }

  const handleGoNextPage = async (event) => {
    event.preventDefault();

    stripe
      .createToken(elements.getElement(CardNumberElement))
      .then(function (result) {

        
        // Handle result.error or result.token
        if (result.token) {

          try {
            setShowSpinner(true);
            axios
              .post(
                "https://us-central1-pcr-test-portal.cloudfunctions.net/cardPayment",
                {
                  token: result.token.id,
                  amount: totalFee,
                }
              )
              .then(async (serverResults) => {
                if (serverResults.status === 200) {
                  await app
                    .firestore()
                    .collection("tests")
                    .doc(history.location.state.detail.id)
                    .set(
                      {
                        stripeChargeID: serverResults.data.data.charge.id,
                        totalFee: totalFee,
                        status: "Pending Physician Review",
                      },
                      { merge: true }
                  );
                  alert("Payment complete");
                  setShowSpinner(false);
                   history.push({
                     pathname: "/dashboard",
                     
                  });
                }
              });
          } catch (error) {
            setShowSpinner(false);
            console.log(error);
          }
        } else {
          console.log("Error");
        }
      });
  };

  if (showSpinner) {
    return (<>
      <div height="100%"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ><Spinner animation="grow" variant="primary" /></div>
    </>);
  } else {

    return (
      <div>
        <article>
          <Header />
          <Card>
            <Card.Body>
              <Card.Title>Make Payment</Card.Title>

              <Form onSubmit={handleGoNextPage}>
                <Form.Group>
                  <Form.Label>Cardholder Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Cardholder Name"
                    value={cardholderName}
                    onChange={(e) => setCardholderName(e.target.value)}
                  />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Cardholder Number</Form.Label>

                  <div id="card-element" className="form-control">
                    <CardNumberElement />
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Card CVV</Form.Label>
                  <div id="card-element" className="form-control">
                    <CardCvcElement />
                  </div>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Card Expiry</Form.Label>
                  <div id="card-element" className="form-control">
                    <CardExpiryElement />
                  </div>
                </Form.Group>
                <div style={{ display: "flex" }}>
                  <Card.Text style={{ marginLeft: "auto" }}>
                    Total: ${totalFee}
                  </Card.Text>
                </div>
                <Button
                  block
                  disabled={!validateSubmit()}
                  onClick={handleGoNextPage}
                  variant="primary"
                >
                  Complete
              </Button>
              </Form>
            </Card.Body>
          </Card>
        </article>
      </div>
    );
  }
};

const PatientPaymentProcess = ({ component: RouteComponent, ...rest }) => {
  return (
    <Elements stripe={stripePromise}>
      <MyCheckoutForm />
    </Elements>
  );
};

export default PatientPaymentProcess;

/*

*/
