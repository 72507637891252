import React, { useState } from "react";
import {
  Button,
  FormGroup,
  FormControl,
   FormLabel,
  Spinner,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import app from "../../context/base.js";
import { useHistory } from "react-router-dom";
import Header from "../../context/Header";

export default function AdminAddEmployer() {
  const history = useHistory();
   const [email, setEmail] = useState("");
   const [employerName, setEmployerName] = useState("");
   const [mainContact, setMainContact] = useState("");
   const [showSpinner, setShowSpinner] = useState(false);
   

  function validateForm() {
    return (
      email.length > 0 && employerName.length > 0 && mainContact.length > 0
    );
  }

   async function handleAdd() {
    const db = await app.firestore();

      try {
        setShowSpinner(true);

        await axios
          .post(
            "https://us-central1-pcr-test-portal.cloudfunctions.net/createUser",
            {
              email: email
            }
          )
          .then(async (serverResults) => {
            setShowSpinner(false);
             if (!serverResults.data.success) {
                console.log(serverResults);
              alert("Something went wrong");
              return;
            }

            await db
              .collection("users").doc(serverResults.data.uid).set({
                email: email,
                employerName: employerName,
                 mainContact: mainContact,
                 uid: serverResults.data.uid,
                type: "Employer",
              })
              .then((results) => {
                console.log(results);
                setShowSpinner(false);
                alert("Employer added successfully");
                history.push("/dashboard");
              });
          });
      } catch (e) {
        setShowSpinner(false);
      }
  }

if (showSpinner) {
   return (<>
      <div height="100%"
         style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
         }}
      ><Spinner animation="grow" variant="primary" /></div>
   </>);
} else {
   return (
      <article>
      <Header />

         <Modal.Dialog>
            <Modal.Header>
               <Modal.Title>PCR Test Member Portal</Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <form onSubmit={handleAdd}>
                  <FormGroup>
                     <FormLabel>Employer Email</FormLabel>
                     <FormControl
                        autoFocus
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                     />
                  </FormGroup>


                  <FormGroup>
                     <FormLabel>Employer Name</FormLabel>
                     <FormControl
                        type="text"
                        value={employerName}
                        onChange={(e) => setEmployerName(e.target.value)}
                     />
                  </FormGroup>


                  <FormGroup>
                     <FormLabel>Main Contact</FormLabel>
                     <FormControl
                        type="text"
                        value={mainContact}
                        onChange={(e) => setMainContact(e.target.value)}
                     />
                  </FormGroup>
                  <Button
                     block
                     disabled={!validateForm()}
                     type="submit"
                  >
                     Create Employer
            </Button>
               </form>
            </Modal.Body>
         </Modal.Dialog>
      </article>
   );
}
}