import React, { useState } from "react";
import {
  Alert,
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Row,
  Col
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import app from "../../context/base.js";

export default function Login() {
  const history = useHistory();

  const [loginError, setLoginError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();

    (async () => {
      try {
        var user = app
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(function () {
            if (user == null) {
              setLoginError(true);
            } else {
              history.push("/dashboard");
            }
          })
          .catch(function (error) {
            setLoginError(true);
          });

        //const db = await app.firestore();
      } catch (error) {
        alert(error);
      }
    })();
  }

  return (
    <div>
      <Alert show={loginError} variant="success">
        <Alert.Heading>Error</Alert.Heading>
        <p>Invaid email or password</p>
        <hr />
        <p className="mb-0">Please try again.</p>
      </Alert>

      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>PCR Test Member Portal</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Email</FormLabel>
              <FormControl
                autoFocus
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <FormLabel>Password</FormLabel>
              <FormControl
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            </FormGroup>
            <Button block disabled={!validateForm()} type="submit">
              Login
            </Button>
            <Row>
              <Col>
                <a style={{ float: "left" }}href="/register">Register</a>
              </Col>
              <Col>
                <a style={{ float: "right" }} href="/forgot-password">Reset Password</a>
              </Col>
            </Row>
            
            
          </form>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
}

/*


<form onSubmit={handleSubmit}>
        <FormGroup formId="email" bsSize="large">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup formId="password" bsSize="large">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <Button block bsSize="large" disabled={!validateForm()} type="submit">
          Login
        </Button>
      </form>

      */
