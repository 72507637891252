import React, { useState, useEffect } from "react";
import Header from "../../context/Header";
import {
  Card,
  Table,
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import app from "../../context/base.js";
import axios from "axios";

const AdminRequestDetails = ({ component: RouteComponent, ...rest }) => {
  const [test, setTest] = useState(null);
  const [passengers, setPassengers] = useState([]);
  const [status, setStatus] = useState("");
  const [resultsLink, setResultsLink] = useState("");
  const history = useHistory();

  useEffect(() => {
    setTest(history.location.state.detail);
    if (history.location.state.detail.resultsLink === undefined) {
      setResultsLink("");
    } else {
      setResultsLink(history.location.state.detail.resultsLink);
    }
    setPassengers(history.location.state.detail.passengers);
    setStatus(history.location.state.detail.status);
  }, [history.location.state.detail]);

  async function handleBack(event) {
    event.preventDefault();
    history.push({
      pathname: "/a-admin-request-list",
    });
  }

  async function updateResults() {
    if (resultsLink === undefined || resultsLink === "") {
      console.log("No");
      return;
    }

    await app
      .firestore()
      .collection("tests")
      .doc(history.location.state.detail.id)
      .set(
        {
          resultsLink: resultsLink,
          status: "Report Send",
        },
        { merge: true }
    );
    
    setStatus("Report Send");

    try {
      axios
        .post(
          "https://us-central1-pcr-test-portal.cloudfunctions.net/sendEmail",
          {
            sendTo: test.email,
            reason: "Request Complete",
          }
        )
        .then(async (serverResults) => {
          console.log(serverResults);
        });
    } catch (error) {
      console.log(error);
    }
    alert("Report submitted");
  }

  async function updateStatus() {
    console.log(history.location.state.detail.id);
    console.log(status);
    await app
      .firestore()
      .collection("tests")
      .doc(history.location.state.detail.id)
      .set(
        {
          status: status,
        },
        { merge: true }
      );

    if (status === "Report Send") {
      console.log(test.email);
      console.log("Request Complete");
      try {
        axios
          .post(
            "https://us-central1-pcr-test-portal.cloudfunctions.net/sendEmail",
            {
              sendTo: test.email,
              reason: "Request Complete",
            }
          )
          .then(async (serverResults) => {
            console.log(serverResults);
          });
      } catch (error) {
        console.log(error);
      }
    }

    alert("Status Updated");
  }

  return (
    <article>
      <Header />
      <Card>
        <Card.Body>
          <Card.Title>Account Holder Email</Card.Title>

          {test?.email !== undefined ? test.email : "No Email"}

          <Card.Text></Card.Text>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Passenger Details</Card.Title>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Passenger Name</th>
                <th>Home Address</th>
                <th>DOB</th>
                <th>Purpose</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(passengers).map((i) => {
                var p = passengers[i];
                return (
                  <tr key={p.name + p.dob}>
                    <td>{p.name}</td>
                    <td>{p.address.address}</td>
                    <td>{p.dob}</td>
                    <td>
                      <b>{p.purpose}</b>
                      <br />
                      <b>School</b> {p.schoolName}
                      <br />
                      <b>Occupation</b> {p.Occupation}
                      <br />
                      <b>Travel Detail</b> {p.travelRegion} {p.travelCountry}
                      <br />
                      <b>Travel Date</b> {p.travelDate}
                      <br />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Lab Details</Card.Title>

          {test?.labSelected?.name !== undefined
            ? test.labSelected.name
            : "Not Selected"}
          {test?.labSelected?.address === undefined ? <></> : <br />}
          {test?.labSelected?.address !== undefined
            ? test?.labSelected?.address
            : ""}
          {test?.labSelected?.turnaroundTime === undefined ? <></> : <br />}
          {test?.labSelected?.turnaroundTime !== undefined
            ? "Turnaround: " + test?.labSelected?.turnaroundTime + " day(s)"
            : ""}
          {test?.labTestDate === undefined ? <></> : <br />}
          {test?.labTestDate !== undefined
            ? "Lab Test Date: " + test?.labTestDate
            : ""}
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Status</Card.Title>
          <Form>
            <Form.Group>
              <Form.Label>Status: {status}</Form.Label>
              <Card.Text>Change Status To:</Card.Text>
              <Form.Control
                as="select"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option>Pending Lab Selection</option>
                <option>Pending Payment</option>
                <option>Pending Physician Review</option>
                <option>Physician Accepted</option>
                <option>Routed to Lab</option>
                <option>Report Send</option>
              </Form.Control>
            </Form.Group>
            <Button onClick={updateStatus} variant="primary">
              Update Status
            </Button>
          </Form>

          <Card.Text></Card.Text>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Update results Link</Card.Title>

          <Form>
            <FormGroup>
              <FormLabel>Send Safely Results Link</FormLabel>
              <FormControl
                autoFocus
                type="text"
                value={resultsLink}
                onChange={(e) => setResultsLink(e.target.value)}
              />
            </FormGroup>
            <Button onClick={updateResults} variant="primary">
              Update Results Link
            </Button>
          </Form>

          <Card.Text></Card.Text>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Stripe Charge ID</Card.Title>

          {test?.stripeChargeID !== undefined
            ? test.stripeChargeID
            : "Not Yet Paid"}

          <Card.Text></Card.Text>
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Paid</Card.Title>

          {test?.totalFee !== undefined ? test.totalFee : "Not Yet Paid"}

          <Card.Text></Card.Text>
        </Card.Body>
      </Card>

      <Button block onClick={handleBack} variant="primary">
        Go Back
      </Button>
    </article>
  );
};

export default AdminRequestDetails;
