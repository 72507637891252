import React, { useState, useEffect, useContext } from "react";
import Header from "../../context/Header";
import app from "../../context/base.js";
import { useHistory } from "react-router-dom";
import { Card, Form } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { AuthContext } from "../../context/Auth.js";

const AdminLabList = ({ component: RouteComponent, ...rest }) => {
  const [labs, setLabs] = useState([]);


  const columns = [
    {
      dataField: "name",
      text: "Lab/Collection Centre Name",
      filter: textFilter(),
    },
    {
      dataField: "turnaroundTime",
      text: "Turnaround Time",
    },
    {
      dataField: "address",
      text: "Address",
      filter: textFilter(),
    },
    {
      dataField: "fax",
      text: "Fax",
      filter: textFilter(),
    },
  ];

  useEffect(() => {
    var list = [];
    app
      .firestore()
      .collection("labs")
      .get()
      .then((snapshot) => {
        var i = 1;
        snapshot.forEach((doc) => {
          var data = doc.data();
          data.id = doc.id;
          data.num = i++;
          list.push(data);
        });
        setLabs(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [labs]);

  const selectRow = {
    mode: "radio",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        //setLabSelected(row);
      } else {
        //setLabSelected();
      }
    },
  };
   
   function handleGoNextPage(event) {

   }

  return (
    <article>
      <Header />

      <Card>
        <Card.Body>
          <Card.Title>All Lab/Collection Centre</Card.Title>

          <Form onSubmit={handleGoNextPage}>

            <BootstrapTable
              keyField="id"
              selectRow={selectRow}
              data={labs}
              columns={columns}
              filter={filterFactory()}
              hover
            />
          </Form>
          <Card.Text></Card.Text>
        </Card.Body>
      </Card>
    </article>
  );
};

export default AdminLabList;
