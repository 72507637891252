import React, { useState, useEffect, useContext } from "react";
import Header from "../../context/Header";
import { AuthContext } from "../../context/Auth.js";
import app from "../../context/base.js";
import { Card, Button, Form, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const PhysicianOpenRequests = ({ component: RouteComponent, ...rest }) => {
  const [tests, setTests] = useState([]);
   
  var { currentUser } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    var myTests = [];
    app
      .firestore()
       .collection("tests")
       .where("status", "==", "Pending Physician Review")
      .get()
      .then((snapshot) => {
        var i = 1;
        snapshot.forEach((doc) => {
          var data = doc.data();
          data.id = doc.id;
          data.num = i++;
          myTests.push(data);
        });
        setTests(myTests);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentUser]);

  function tableRows(test) {
    return (
      <td>
        {test.labSelected?.name !== undefined
          ? test.labSelected.name
          : "Not Selected"}
        {test.labSelected?.address === undefined ? <></> : <br />}
        {test.labSelected?.address !== undefined ? test.labSelected?.address : ""}
        {test.labSelected?.turnaroundTime === undefined ? <></> : <br />} 
        {test.labSelected?.turnaroundTime !== undefined
          ? "Turnaround: " + test.labSelected?.turnaroundTime + " day(s)"
          : ""}
      </td>
    );
  }

  async function openRequest (test)  {

    await app
      .firestore()
      .collection("tests")
      .doc(test.id)
      .set(
        {
          status: "Physician Accepted",
          physicianAccepted: currentUser.uid,
        },
        { merge: true });
    
    history.push({
      pathname: "/p-physician-view-my-requests"
    });
  }

  function handleGoNextPage() {}

  return (
    <article>
      <Header />

      <Card>
        <Card.Body>
          <Card.Title>My History</Card.Title>
          <Form onSubmit={handleGoNextPage}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Number of Passengers</th>
                  <th>Collection Site or Lab</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tests.map((test) => {
                  return (
                    <tr key={test.num}>
                      <td>{test.numPassengers}</td>
                      {tableRows(test)}
                      <td>
                          <Button
                            onClick={() => openRequest(test)}
                            variant="primary"
                          >
                            Accept
                          </Button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Card.Text></Card.Text>
          </Form>
          <Card.Text></Card.Text>
        </Card.Body>
      </Card>
    </article>
  );
};

export default PhysicianOpenRequests;