import React, { useContext } from "react";
import { AuthContext } from "./Auth.js";

import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import app from "../context/base.js";
import { useHistory } from "react-router-dom";

const Header = ({ component: RouteComponent, ...rest }) => {
  var { currentUser } = useContext(AuthContext);
  const history = useHistory();

  function signOutUser() {
    app.auth().signOut();
    history.push("/");
  }

  var employerNav = (
    <div>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/e-dashboard">PCR Test</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/e-dashboard">Home</Nav.Link>
            <Nav.Link href="/e-all-tests">All Tests</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <a href="/#" onClick={signOutUser}>Logout</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );


  var patientNav = (
    <div>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="#home">PCR Test</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/dashboard">Home</Nav.Link>
            <Nav.Link href="/p-passenger-data">Create Request</Nav.Link>
            <Nav.Link href="/p-passenger-view-all-requests">View all Request</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <a href="/#" onClick={signOutUser}>Logout</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );

  var phytNav = (
    <div>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/dashboard">PCR Test</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/dashboard">Home</Nav.Link>
            <Nav.Link href="p-physician-view-all-requests">View open requests</Nav.Link>
            <Nav.Link href="p-physician-view-my-requests">My accepted requests</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <a href="/#" onClick={signOutUser}>Logout</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );

  var labNav = (
    <div>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/dashboard">PCR Test</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/dashboard">Home</Nav.Link>
            <Nav.Link href="#link">My Lab</Nav.Link>
            <Nav.Link href="#link">Upload Results</Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <a href="/#" onClick={signOutUser}>Logout</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );

  var adminNav = (
    <div>
      <Navbar bg="light" expand="lg">
        <Navbar.Brand href="/dashboard">PCR Test</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/dashboard">Home</Nav.Link>
            <NavDropdown title="Auth Requests" id="basic-nav-dropdown">
              <NavDropdown.Item href="/a-admin-request-list">View all Patient Requests</NavDropdown.Item>
              <NavDropdown.Item href="/a-admin-physician">View all Physicians</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/a-admin-custom-labs">View Custom Collection Site</NavDropdown.Item>
              <NavDropdown.Item href="/a-admin-labs">View all Collection Sites</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Employers" id="basic-nav-dropdown">
              <NavDropdown.Item href="/a-admin-all-employer">All Employers</NavDropdown.Item>
              <NavDropdown.Item href="/a-admin-add-employer">Add Employer</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown title="UnAuth Requests" id="basic-nav-dropdown">
              <NavDropdown.Item href="/a-admin-unauth-results-pending">Open Synergy Requests</NavDropdown.Item>
              <NavDropdown.Item href="/a-admin-unauth-results-complete">Complete Synergy Requests</NavDropdown.Item>
              <NavDropdown.Item href="/a-admin-unauth-results-add">Add Synergy Request</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/a-admin-unauth-csv">CSV uploader</NavDropdown.Item>
            </NavDropdown>


            <NavDropdown title="Venue" id="basic-nav-dropdown">
              <NavDropdown.Item href="/a-venue-qr">Scan QR</NavDropdown.Item>
              <NavDropdown.Item href="/a-venue-pending">Pending Tests</NavDropdown.Item>
              <NavDropdown.Item href="/a-venue-complete">Completed Tests</NavDropdown.Item>
            </NavDropdown>
            
            
            
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          <Navbar.Text>
            <a href="/#" onClick={signOutUser}>Logout</a>
          </Navbar.Text>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );

  

  var navBarHeader = "<div></div>";
  if (currentUser.type === "Patient") {
    navBarHeader = patientNav;
  } else if (currentUser.type === "Physician") {
    navBarHeader = phytNav;
  } else if (currentUser.type === "Lab") {
    navBarHeader = labNav;
  } else if (currentUser.type === "Admin") {
    navBarHeader = adminNav;
  } else if (currentUser.type === "Employer") {
    navBarHeader = employerNav;
  }

  return <div>{navBarHeader}</div>;
};

export default Header;
