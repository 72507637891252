import React, { useContext } from "react";
import Header from "../../context/Header";
import { Card, Container, Row, Col } from "react-bootstrap";
import { AuthContext } from "../../context/Auth.js";
import { useHistory } from "react-router-dom";

const Dashboard = ({ component: RouteComponent, ...rest }) => {
  var { currentUser } = useContext(AuthContext);
  const history = useHistory();

  if (currentUser.type === "Patient") {
    return (
      <article>
        <Header />

        <Container>
          <h3>Why do I need a COVID Test?</h3>
          <Row>
            <Col xs={6}>
              {" "}
              <Card>
                <a href="/p-passenger-data">
                  <Card.Img
                    variant="top"
                    src="https://gowlingwlg.com/getmedia/aa968379-cdfb-4339-9b0e-d0db5cf1bc64/200526-plane-travel-restrictions.jpg"
                  />
                </a>
                <Card.Body>
                  <Card.Title>Travel</Card.Title>
                  <Card.Text>
                    To meet requirements from airlines or destinations
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6}>
              {" "}
              <Card>
                <a href="/p-passenger-data">
                  <Card.Img
                    variant="top"
                    src="https://gowlingwlg.com/getmedia/aa968379-cdfb-4339-9b0e-d0db5cf1bc64/200526-plane-travel-restrictions.jpg"
                  />
                </a>
                <Card.Body>
                  <Card.Title>School</Card.Title>
                  <Card.Text>
                    For clearance to return back to school
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>&nbsp;</Row>
          <Row>
            <Col xs={6}>
              {" "}
              <Card>
                <a href="/p-passenger-data">
                  <Card.Img
                    variant="top"
                    src="https://www.personneltoday.com/wp-content/uploads/sites/8/2020/07/face-covering-return-to-work-shutterstock_1773703454.jpg"
                  />
                </a>
                <Card.Body>
                  <Card.Title>Work</Card.Title>
                  <Card.Text>
                    To return back to the office safely
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col xs={6}>
              {" "}
              <Card>
                <a href="/p-passenger-data">
                  <Card.Img
                    variant="top"
                    src="https://www.workerscompsacto.com/wp-content/uploads/2018/11/future-medical-treatment-768x432.jpg"
                  />
                </a>
                <Card.Body>
                  <Card.Title>Medical</Card.Title>
                  <Card.Text>
                    Get clearance prior to procedures (surgeries, treatments)
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </article>
    );
  } if (currentUser.type === "Employer") {
    history.push("/e-dashboard");
    return null;
  } else {
    return <article> <Header /></article>;
  }
};

export default Dashboard;
