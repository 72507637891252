import React, { useState } from "react";
import {
   Button,
   Form,
  FormGroup,
  FormControl,
   FormLabel,
  Spinner,
} from "react-bootstrap";
import { Navbar } from "react-bootstrap";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import app from "../../context/base.js";
import { useHistory } from "react-router-dom";

export default function VenueAddRequests() {
  const history = useHistory();
   const [email, setEmail] = useState("");
   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [dob, setDOB] = useState("");
   const [venue, setVenue] = useState("");
   const [address, setAddress] = useState(null);
   const [showSpinner, setShowSpinner] = useState(false);
   
  function validateForm() {
    return (
      email.length > 0 && firstName.length > 0 && lastName.length > 0 && dob.length > 0 && !isEmpty(address)
    );
  }
   
   function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

   async function handleAdd() {
    const db = await app.firestore();
      try {
         setShowSpinner(true);

         if (!isEmpty(address)) {
            geocodeByPlaceId(address.value.place_id)
               .then(async(results) => {
                  const myAddress = {
                     address: results[0].formatted_address,
                     lat: results[0].geometry.location.lat(),
                     lng: results[0].geometry.location.lng(),
                  };

                  var address = results[0].address_components;
                  var zipcode = address[address.length - 1].long_name;
                  zipcode = zipcode.replace(/\s+/g, '');
                  
                  var yearOfBirth = dob.split("-")[0];
                  var myVenue = venue;
                  var referenceID = lastName + yearOfBirth + zipcode;

                  if (myVenue === "") {
                     myVenue = "Venue not provided."
                  }
                  await db.collection("venue-tests").add({
                     email: email,
                     firstName: firstName,
                     lastName: lastName,
                     dob: dob,
                     address: myAddress,
                     venue: myVenue,
                     createdOn: Date.now(),
                     type: "venue",
                     referenceID: referenceID,
                     status: "Pending"
                  })
                     .then(async (results) => {
                        console.log(results.id);
                     await axios
                     .post(
                        "https://us-central1-pcr-test-portal.cloudfunctions.net/customEmail",
                        {
                           email: email,
                           firstName: firstName,
                           lastName: lastName,
                           subject: "The PCR Test - Request Received",
                           venue: myVenue,
                           reason: "registration",
                           reference: referenceID,
                           id: results.id
                        }
                     )
                        .then(async (serverResults) => {
                        alert("Your request was received. A confirmation email will be sent to you.");
                     history.push("/check-in");
                        setShowSpinner(false);
                     });
                     
                     
                  });
               })
         }
      } catch (e) {
        setShowSpinner(false);
      }
  }

if (showSpinner) {
   return (<>
      <div height="100%"
         style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
         }}
      ><Spinner animation="grow" variant="primary" /></div>
   </>);
} else {
   return (
      <article>

         <Navbar bg="dark" expand="lg">        
        <Navbar.Brand style={{ color: 'white'}}>SYNERGY HEALTH ADVISORS</Navbar.Brand>
      </Navbar>

         <Modal.Dialog>
            <Modal.Header>
               <Modal.Title>PCR Test - Venue check-in</Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <form onSubmit={handleAdd}>
                  <FormGroup>
                     <FormLabel>First Name</FormLabel>
                     <FormControl
                        autoFocus
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                     />
                  </FormGroup>

                  <FormGroup>
                     <FormLabel>Last Name</FormLabel>
                     <FormControl
                        
                        type="text"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                     />
                  </FormGroup>


                  <FormGroup>
                     <FormLabel>Email</FormLabel>
                     <FormControl
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                     />
                  </FormGroup>


                  <Form.Group>
              <Form.Label>Home Address</Form.Label>
              <GooglePlacesAutocomplete
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["us", "ca"],
                  },
                }}
                id="addressField"
                selectProps={{
                  address,
                  onChange: setAddress,
                }}
              />
            </Form.Group>

                  <Form.Group>
              <Form.Label>DOB</Form.Label>
              <Form.Control
                type="date"
                placeholder="DOB"
                value={dob}
                onChange={(e) => setDOB(e.target.value)}
              />
                  </Form.Group>
                  
                  <FormGroup>
                     <FormLabel>Venue</FormLabel>
                     <FormControl
                        
                        type="text"
                        value={venue}
                        onChange={(e) => setVenue(e.target.value)}
                     />
                  </FormGroup>


                  <Button
                     block
                     disabled={!validateForm()}
                     type="submit"
                  >
                     Send Request
            </Button>
               </form>
            </Modal.Body>
         </Modal.Dialog>
      </article>
   );
}
}