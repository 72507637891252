import React, { useState } from "react";
import {
  Card,
  Button,
  FormGroup,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import { Navbar, Nav } from "react-bootstrap";
import app from "../../context/base.js";

const CheckResults = ({ component: RouteComponent, ...rest }) => {
  const [dateOfTest, setDateOfTest] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDOB] = useState("");
  //const [zip, setZip] = useState("");
  const [result, setResult] = useState({});
  const [typeOfTest, setTypeOfTest] = useState("");

  const [status, setStatus] = useState("");
  const [resultsLink, setResultsLink] = useState("");

  async function searchResults() {
    if (typeOfTest === "" || dateOfTest === "" || firstName === "" || lastName === "" || dob === "") {
      alert("Error: All fields are mandatory");
      return;
    }

    var list = [];
    app
      .firestore()
      .collection("unAuthTests")
      .where("dateOfTest", "==", dateOfTest)
       //.where("lastName", "==", lastName)
       .where("dob", "==", dob)
      .where("typeOfTest", "==", typeOfTest)
      .get()
      .then((snapshot) => {
        var i = 1;
        snapshot.forEach((doc) => {
          
          var data = doc.data();
          if (data.lastName.toLowerCase().trim() === lastName.toLowerCase().trim() && data.firstName.toLowerCase().trim() === firstName.toLowerCase().trim()) {
            data.id = doc.id;
            data.num = i++;
            list.push(data);
          }
          
        });
        if (list.length === 0) {
          alert("Not Found");
        } else if (list.length > 1) {
          alert("There was an issue.");
        } else {
           setResult(list[0]);
           setResultsLink(list[0].resultsLink);
           setStatus(list[0].status);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
   
   

  return (
    <article>

      
      <Navbar bg="dark" expand="lg">        
        <Navbar.Brand style={{ color: 'white'}}>SYNERGY HEALTH ADVISORS</Navbar.Brand>
      </Navbar>
        
        {status !== "" ?
        <Card  style={{ backgroundColor: '#90EE90'}}>
          <Card.Body>
                 <Card.Title>Results</Card.Title>
                 Status: {status} <br />
                 Results: {resultsLink === "" ? <></> : <a href={resultsLink}>Click Here</a>}
            
          </Card.Body>
        </Card> : <></>}

      <Card>
        <Card.Body>
          <Card.Title>COVID-19 Test - Check Result</Card.Title>


           <FormGroup>
            <FormLabel>First Name</FormLabel>
            <FormControl
              autoFocus
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Last Name</FormLabel>
            <FormControl
              autoFocus
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Type of Test </FormLabel>
            <FormControl
              as="select"
              value={typeOfTest}
              onChange={(e) => setTypeOfTest(e.target.value)}
            >
              <option></option>
              <option>PCR swab</option>
              <option>Antibody</option>
              <option>Rapid Test</option>
              <option>Molecular</option>
            </FormControl>
          </FormGroup>

          <FormGroup>
            <FormLabel>Test Date</FormLabel>
            <FormControl
              
              type="date"
              value={dateOfTest}
              onChange={(e) => setDateOfTest(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Date of Birth</FormLabel>
            <FormControl
              
              type="date"
              value={dob}
              onChange={(e) => setDOB(e.target.value)}
            />
          </FormGroup>
        </Card.Body>
      </Card>

      <Button block onClick={searchResults} variant="dark">
        Search Results
      </Button>
      <br />
      <br />
    </article>
  );
};

export default CheckResults;
