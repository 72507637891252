import React, { useState, useEffect } from "react";
import Header from "../../context/Header";
import {
  Card,
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import app from "../../context/base.js";
import axios from "axios";

const AdminVenueDetails = ({ component: RouteComponent, ...rest }) => {
  const history = useHistory();
  const [id, setID] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [venue, setVenue] = useState("");
  const [referenceID, setReferenceID] = useState("");
  const [uniqueID, setUniqueID] = useState("");
  const [dob, setDOB] = useState("");
  const [resultOutcome, setResultOutcome] = useState("");

  useEffect(() => {
    console.log("*");
    setID(history.location.state.detail.id);
    setEmail(history.location.state.detail.email);
    setVenue(history.location.state.detail.venue);
    setFirstName(history.location.state.detail.firstName);
    setLastName(history.location.state.detail.lastName);
    setDOB(history.location.state.detail.dob);
    setReferenceID(history.location.state.detail.referenceID);
    setUniqueID(history.location.state.detail.uniqueID);
    setResultOutcome(history.location.state.detail.resultOutcome ?? "");
  }, [history.location.state.detail, history.location.state.detail.id]);

  async function updateResults() {
    if (
      resultOutcome === undefined ||
      resultOutcome === null ||
      resultOutcome === ""
    ) {

       
       await app.firestore().collection("venue-tests").doc(id).update(
      {
          resultOutcome: "",
         status: "Pending"
      },
      { merge: true }
    );
       
       
       
      history.push("/a-venue-pending");
      return;
    }
    await app.firestore().collection("venue-tests").doc(id).update(
      {
          resultOutcome: resultOutcome,
         status: "Complete"
      },
      { merge: true }
    );

    try {
      await axios
        .post(
          "https://us-central1-pcr-test-portal.cloudfunctions.net/customEmail",
          {
            email: email,
            firstName: firstName,
            lastName: lastName,
            subject: "The PCR Test - Results Available",
            venue: venue,
            reason: resultOutcome,
            reference: uniqueID,
             id: id,
            
          }
        )
        .then(async (serverResults) => {
          alert(
            "Your request was received. A confirmation email will be sent to you."
          );
          history.push("/a-venue-pending");
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <article>
      <Header />

      <Card>
        <Card.Body>
          <Card.Title>Detail</Card.Title>

          <FormGroup>
            <FormLabel>Email</FormLabel>
            <FormControl readOnly type="text" value={email} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Reference ID</FormLabel>
            <FormControl readOnly type="text" value={referenceID} />
          </FormGroup>

          <FormGroup>
            <FormLabel>First Name</FormLabel>
            <FormControl readOnly type="text" value={firstName} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Last Name</FormLabel>
            <FormControl readOnly type="text" value={lastName} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Venue</FormLabel>
            <FormControl readOnly type="text" value={venue} />
          </FormGroup>

          <FormGroup>
            <FormLabel>Date of Birth</FormLabel>
            <FormControl readOnly type="date" value={dob} />
          </FormGroup>

          <Form.Group>
            <Form.Label>Result Outcome </Form.Label>
            <Form.Control
              as="select"
              value={resultOutcome}
              onChange={(e) => setResultOutcome(e.target.value)}
            >
              <option> </option>
              <option>Positive</option>
              <option>Negative</option>
              <option>Inconclusive</option>
            </Form.Control>
          </Form.Group>
        </Card.Body>
      </Card>

      <Button block onClick={updateResults} variant="primary">
        Submit
      </Button>
      <br />
      <br />
    </article>
  );
};

export default AdminVenueDetails;

/*
<Form.Group>
            <Form.Label>Status: </Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option></option>
              <option>Results Pending</option>
              <option>Results Available</option>
            </Form.Control>
          </Form.Group>
*/
