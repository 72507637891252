import React, { useState } from "react";
//import QrReader from "react-qr-scanner";
import QrReader from 'react-qr-reader'
import { useHistory } from "react-router-dom";
import Header from "../../context/Header";
import { Modal, FormGroup, Button } from "react-bootstrap";
import app from "../../context/base.js";

export default function AdminVenueQR() {
  const history = useHistory();
  const [qr, setQR] = useState("");
  const [venueTest, setVenueTest] = useState(null);

  async function handleScan(dataID)  {
    
    if (dataID !== null) {
      setQR(dataID.trim());

      const db = await app.firestore();
      var loadData = await db.collection("venue-tests").doc(dataID.trim()).get();
      if (loadData.exists) {
        var myData = loadData.data();
        myData.id = dataID.trim();
        history.push({
              pathname: "/a-venue-details",
              state: { detail: myData },
            });
      }
      //console.log(loadData);
      //console.log(loadData.data());
      //loadData.id = dataID;
      //setVenueTest(loadData);
      
/*
      app
        .firestore()
        .collection("venue-tests")
        .doc(dataID)
        .get()
        .then((snapshot) => {
            setVenueTest(snapshot.data());
            console.log(snapshot.data);
            var myData = {};
            myData = snapshot.data();
            console.log(myData);
            
            history.push({
              pathname: "/a-venue-details",
              state: { detail: myData },
            });
        })
        .catch((err) => {
          console.log(err);
        });*/
    }
    //console.log(data);
  }

  function goToDetails() {
    app
        .firestore()
        .collection("venue-tests")
        .doc(qr)
        .get()
        .then((snapshot) => {
          setVenueTest(snapshot.data());
          console.log(snapshot);
          var myData = snapshot.data();
          myData.id = qr;
          history.push({
            pathname: "/a-venue-details",
            state: { detail: myData },
          });
        });

  }

  function handleError(err) {
    console.error(err);
  }

  const previewStyle = {
    height: 240,
    width: 320,
  };

  return (
    <article>
      <Header />

      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>PCR Test - Venue check-in</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form>
            <FormGroup>
              <div>

                <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          style={{ width: '100%' }}
        />
                {qr !== "" ? (
              <Button block onClick={goToDetails} variant="primary">Submit</Button>
                ) : (
              <></>
                  )}
                

              </div>
            </FormGroup>
          </form>
        </Modal.Body>
      </Modal.Dialog>
    </article>
  );
}
