import React, { useState, useEffect } from "react";
import Header from "../../context/Header";
import { Card, Table, Button, Form, Modal, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import axios from "axios";
import app from "../../context/base.js";
import PhysicianDraftScript from "./PhysicianDraftScript";

const PhysicianRequestDetails = ({ component: RouteComponent, ...rest }) => {
  const [test, setTest] = useState(null);
  const [physician, setPhysician] = useState(null);
  const [passengers, setPassengers] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const history = useHistory();

  const [showSpinner, setShowSpinner] = useState(false);

  const handleClose = () => setDisplayModal(false);
  const handleShow = () => setDisplayModal(true);

  useEffect(() => {
    setTest(history.location.state.detail);
    setPassengers(history.location.state.detail.passengers);

    if (test?.physicianAccepted != null) {
      app
        .firestore()
        .collection("users")
        .doc(test.physicianAccepted)
        .get()
        .then((snapshot) => {
          setPhysician(snapshot.data());
        });
    }
  }, [test, history.location.state.detail]);

  async function handleBack(event) {
    event.preventDefault();
    history.push({
      pathname: "/p-physician-view-my-requests",
    });
  }

  function createScript() {
    setDisplayModal(false);
    
    alert("Creating scripts will take up to 30 seconds.");
    setShowSpinner(true);
    try {
      axios
        .post(
          "https://us-central1-pcr-test-portal.cloudfunctions.net/generateScripts",
          {
            physicianID: test.physicianAccepted,
            testID: test.id,
          }
        )
        .then(async (serverResults) => {
          setShowSpinner(false);
          alert("Scripts created.");
          history.push({
            pathname: "/p-physician-view-my-requests",
          });
        });
    } catch (error) {
      console.log(error);
    }
  }

  async function updateReason(index, purpose) {
    let passengerList = passengers;
    passengerList[index].testPurpose = purpose;

    app
      .firestore()
      .collection("tests")
      .doc(history.location.state.detail.id)
      .set(
        {
          passengers: Object.assign({}, passengers),
        },
        { merge: true }
      );
  }

  if (showSpinner) {
    return (<>
      <div height="100%"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ><Spinner animation="grow" variant="primary" /></div>
    </>);
  } else {
    return (
      <article>
        <Header />

        <>
          <Modal show={displayModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>View Scripts</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {Object.keys(passengers).map((i) => {
                var p = passengers[i];
                return (
                  <PhysicianDraftScript
                    key={p.name + p.dob}
                    test={test}
                    physician={physician}
                    passenger={p}
                  />
                );
              })}
            </Modal.Body>

            <Modal.Footer>
              <Button onClick={() => handleClose()} variant="secondary">
                Close
            </Button>
              <Button onClick={() => createScript()} variant="primary">
                Create Script
            </Button>
            </Modal.Footer>
          </Modal>
        </>
        <Card>
          <Card.Body>
            <Card.Title>Passenger Details</Card.Title>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Passenger Name</th>
                  <th>Home Address</th>
                  <th>DOB</th>
                  <th>Script</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(passengers).map((i) => {
                  var p = passengers[i];
                  return (
                    <tr key={p.name + p.dob}>
                      <td>{p.name}</td>
                      <td>{p.address.address}</td>
                      <td>{p.dob}</td>
                      <td>
                        {p.scriptURL != null ? (
                          <a href={p.scriptURL}>Click here</a>
                        ) : (
                            <></>
                          )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <Card.Title>Status</Card.Title>
            {test?.status}
            <br/>
            { (test?.status === "Physician Accepted") ? <b style={{ color: "red" }}>Request is awaiting your response, please click "Create Script for all passengers" button below.</b> : <></>}

            <Card.Text></Card.Text>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <Card.Title>Create Script</Card.Title>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>Passenger Name</th>
                  <th>Purpose</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(passengers).map((i) => {
                  var p = passengers[i];
                  return (
                    <tr key={p.name + p.dob}>
                      <td>{p.name}</td>
                      <td>
                        <Form>
                          <Form.Control
                            as="select"
                            value={passengers[i].testPurpose}
                            onChange={(e) => updateReason(i, e.target.value)}
                          >
                            <option></option>
                            <option>z41.8 Travel Medicine</option>
                            <option>u07.1 COVID-19</option>
                          </Form.Control>
                        </Form>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <Button variant="primary" onClick={() => handleShow()}>
              Create Script for all passengers
          </Button>
          </Card.Body>
        </Card>

        <Card>
          <Card.Body>
            <Card.Title>Collection Site or Lab Details</Card.Title>

            {test?.labSelected?.name !== undefined
              ? test.labSelected.name
              : "Not Selected"}
            {test?.labSelected?.address === undefined ? <></> : <br />}
            {test?.labSelected?.address !== undefined
              ? test?.labSelected?.address
              : ""}
            {test?.labSelected?.turnaroundTime === undefined ? <></> : <br />}
            {test?.labSelected?.turnaroundTime !== undefined
              ? "Turnaround: " + test?.labSelected?.turnaroundTime + " day(s)"
              : ""}
          </Card.Body>
        </Card>

        

        <Button block onClick={handleBack} variant="primary">
          Go Back
      </Button>
        <br/><br/>
      </article>
    );
  }
};

export default PhysicianRequestDetails;
