import React, { useState } from "react";
import {
  Alert,
  Button,
  FormGroup,
  FormControl,
  FormLabel,
  Row,
  Col
} from "react-bootstrap";
import { useHistory } from "react-router-dom";

import Modal from "react-bootstrap/Modal";
import app from "../../context/base.js";

export default function ForgotPassword() {
  const history = useHistory();
  const [email, setEmail] = useState("");

  function validateForm() {
    return email.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();

    (async () => {
      try {
        var user = app
          .auth()
          .sendPasswordResetEmail(email)
           .then(function () {
              alert("Please check your email");

              history.push("/");
            
          })
          .catch(function (error) {
          });

        //const db = await app.firestore();
      } catch (error) {
        alert(error);
      }
    })();
  }

  return (
    <div>
      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>PCR Test Member Portal - Password Reset</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <FormLabel>Email</FormLabel>
              <FormControl
                autoFocus
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <Button block disabled={!validateForm()} type="submit">
              Reset Password
            </Button>
          </form>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
}
