import React, { useState, useEffect, useContext, useRef } from "react";
import Header from "../../context/Header";
import app from "../../context/base.js";
import { useHistory } from "react-router-dom";
import { Card, Button, Form } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import { AuthContext } from "../../context/Auth.js";

const PatientSelectLab = ({ component: RouteComponent, ...rest }) => {
  const [testDate, setTestDate] = useState("");
  const [labSelected, setLabSelected] = useState(null);
  const [labs, setLabs] = useState([]);
  const [passengerData, setPassengerData] = useState(null);
  const history = useHistory();
  const tableRef = useRef(null);

  const [customLabName, setCustomLabName] = useState("");
  const [customLabAddress, setCustomLabAddress] = useState(null);
  const [customLabFax, setCustomLabFax] = useState("");
  const [displayCustomLabForm, setDisplayCustomLabForm] = useState(false);

  var { currentUser } = useContext(AuthContext);

  const columns = [
    {
      dataField: "name",
      text: "Collection Site or Lab",
      filter: textFilter(),
    },
    {
      dataField: "turnaroundTime",
      text: "Turnaround Time",
    },
    {
      dataField: "address",
      text: "Address",
      filter: textFilter(),
    },
  ];

  useEffect(() => {
    setPassengerData(history.location.state.detail);

    var list = [];
    app
      .firestore()
      .collection("labs")
      .get()
      .then((snapshot) => {
        var i = 1;
        snapshot.forEach((doc) => {
          var data = doc.data();
          data.id = doc.id;
          data.num = i++;
          list.push(data);
        });

        for (i = 0; i < list.length; i++) {
          list[i]["distance"] = calculateDistance(
            passengerData?.passengers[0].address.lat,
            passengerData?.passengers[0].address.lng,
            list[i]["lat"],
            list[i]["lng"],
            "K"
          );
        }

        list.sort(function (a, b) {
          return a.distance - b.distance;
        });

        setLabs(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [passengerData, history.location.state.detail]);

  function calculateDistance(lat1, lon1, lat2, lon2, unit) {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    //var radlon1 = (Math.PI * lon1) / 180;
    //var radlon2 = (Math.PI * lon2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist = dist * 1.609344;
    }
    if (unit === "N") {
      dist = dist * 0.8684;
    }
    return dist;
  }

  function validateSubmit() {
    return (
      testDate.length > 0 &&
      (!isEmpty(labSelected) ||
        (customLabName !== "" &&
          !isEmpty(customLabAddress) &&
          customLabFax.length > 9))
    );
  }

  async function handleGoNextPage(event) {
    event.preventDefault();

    if (
      isEmpty(labSelected) &&
      customLabName !== "" &&
      !isEmpty(customLabAddress) &&
      customLabFax.length > 9
    ) {
      geocodeByPlaceId(customLabAddress.value.place_id).then(
        async (results) => {
          var customLab = {
            name: customLabName,
            type: "custom",
            address: results[0].formatted_address,
            fax: customLabFax,
            id: customLabAddress.value.place_id,
            createdOn: Date.now(),
            createdByUID: currentUser.uid,
            createdByEmail: currentUser.email,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };

          await app.firestore().collection("customEnteredLabs").add({
            customLab,
          });

          await app
            .firestore()
            .collection("tests")
            .doc(history.location.state.detail.id)
            .set(
              {
                labTestDate: testDate,
                labSelected: customLab,
                status: "Pending Payment",
              },
              { merge: true }
            );
          passengerData.labSelected = customLab;
        }
      );
    } else {
      await app
        .firestore()
        .collection("tests")
        .doc(history.location.state.detail.id)
        .set(
          {
            labTestDate: testDate,
            labSelected: labSelected,
            status: "Pending Payment",
          },
          { merge: true }
        );
      passengerData.labSelected = labSelected;
    }
    passengerData.labTestDate = testDate;

    history.push({
      pathname: "/p-passenger-payment-process",
      state: { detail: passengerData },
    });
  }

  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  const selectRow = {
    mode: "radio",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setLabSelected(row);
      } else {
        setLabSelected();
      }
    },
  };

  return (
    <article>
      <Header />

      <Card>
        <Card.Body>
          <Card.Title>Collection Site or Lab</Card.Title>

          <Form onSubmit={handleGoNextPage}>
            <Form.Group>
              <Form.Label>Test Date</Form.Label>
              <Form.Control
                type="date"
                placeholder="Test Date"
                value={testDate}
                onChange={(e) => setTestDate(e.target.value)}
              />
            </Form.Group>

            {!displayCustomLabForm ? <BootstrapTable
              keyField="id"
              selectRow={selectRow}
              data={labs}
              columns={columns}
              filter={filterFactory()}
              hover
            /> : <></>}

            <Card.Text>
              Like to route to your Lab or Collection Centre? Enter information below to get
              your script routed to that location.
            </Card.Text>

            <Form.Group controlId="formBasicCheckbox">
              <Form.Check
                onChange={(e) => setDisplayCustomLabForm(e.target.checked) }
                type="checkbox"
                label="I wish to use my own Lab or Collection Centre."
              />
            </Form.Group>

            {displayCustomLabForm ? (
              <Card>
                <Card.Body>
                  <Card.Title>Wish to use a different lab?</Card.Title>
                  <Form.Group>
                    <Form.Label>Collection Site or Lab</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter name"
                      value={customLabName}
                      onChange={(e) => setCustomLabName(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Collection Site or Lab Address</Form.Label>
                    <GooglePlacesAutocomplete
                      autocompletionRequest={{
                        componentRestrictions: {
                          country: ["us", "ca"],
                        },
                      }}
                      id="addressField"
                      selectProps={{
                        customLabAddress,
                        onChange: setCustomLabAddress,
                      }}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Collection Site or Lab Fax</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Fax (800)-839-2920"
                      value={customLabFax}
                      onChange={(e) => setCustomLabFax(e.target.value)}
                    />
                  </Form.Group>
                </Card.Body>
              </Card>
            ) : (
              <></>
            )}

            <Button
              block
              disabled={!validateSubmit()}
              onClick={handleGoNextPage}
              variant="primary"
            >
              Go to next step
            </Button>
          </Form>
          <Card.Text></Card.Text>
        </Card.Body>
      </Card>
    </article>
  );
};

export default PatientSelectLab;
