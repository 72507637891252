import React, { useState, useEffect } from "react";
import {
  Alert,
  Button,
  FormGroup,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import app from "../../context/base.js";
import { useHistory } from "react-router-dom";

export default function Register() {
  const history = useHistory();
  const [loginError, setLoginError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  function validateForm() {
    return (
      email.length > 0 && password.length > 0 && password == confirmPassword
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();

    var user = await app.auth().createUserWithEmailAndPassword(email, password);

    if (user != null) {
      const db = await app.firestore();
      var loadData = await db.collection("users").doc(user.user.uid).set({
        type: "Patient",
        email: email,
        uid: user.user.uid,
        createdOn: Date.now(),
      });
      history.push("/dashboard");
    }
  }

  return (
    <div>
      <Alert show={loginError} variant="success">
        <Alert.Heading>Error</Alert.Heading>
        <p>Invaid email or password</p>
        <hr />
        <p className="mb-0">Please try again.</p>
      </Alert>

      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>PCR Test Member Portal</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <FormGroup formId="email" bsSize="large">
              <FormLabel>Email</FormLabel>
              <FormControl
                autoFocus
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup formId="password" bsSize="large">
              <FormLabel>Password</FormLabel>
              <FormControl
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
              />
            </FormGroup>
            <FormGroup formId="confirmPassword" bsSize="large">
              <FormLabel>Confirm Password</FormLabel>
              <FormControl
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
              />
            </FormGroup>
            <Button
              block
              bsSize="large"
              disabled={!validateForm()}
              type="submit"
            >
              Login
            </Button>
          </form>
        </Modal.Body>
      </Modal.Dialog>
    </div>
  );
}

/*


<form onSubmit={handleSubmit}>
        <FormGroup formId="email" bsSize="large">
          <FormLabel>Email</FormLabel>
          <FormControl
            autoFocus
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup formId="password" bsSize="large">
          <FormLabel>Password</FormLabel>
          <FormControl
            value={password}
            onChange={e => setPassword(e.target.value)}
            type="password"
          />
        </FormGroup>
        <Button block bsSize="large" disabled={!validateForm()} type="submit">
          Login
        </Button>
      </form>

      */
