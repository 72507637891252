import React, { useState, useEffect } from "react";
import Header from "../../context/Header";
import { Card, Table, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const PatientRequestDetails = ({ component: RouteComponent, ...rest }) => {
  const [test, setTest] = useState(null);
  const [passengers, setPassengers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    setTest(history.location.state.detail);
    setPassengers(history.location.state.detail.passengers);
  }, [test, history.location.state.detail]);

  async function handleBack(event) {
    event.preventDefault();
    history.push({
      pathname: "/p-passenger-view-all-requests",
    });
  }

  return (
    <article>
      <Header />
      <Card>
        <Card.Body>
          <Card.Title>Passenger Details</Card.Title>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>Passenger Name</th>
                <th>Home Address</th>
                <th>DOB</th>
                <th>Script</th>
               
              </tr>
            </thead>
            <tbody>
              {Object.keys(passengers).map((i) => {
                var p = passengers[i];
                return (
                  <tr key={p.name + p.dob}>
                    <td>{p.name}</td>
                    <td>{p.address.address}</td>
                    <td>{p.dob}</td>
                    <td>
                      {p.scriptURL != null ? (
                        <a href={p.scriptURL}>Click here</a>
                      ) : (
                        <></>
                      )}
                    </td>
                    
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {test?.resultsLink !== undefined ?
        <Card>
          <Card.Body>
            <Card.Title>Results</Card.Title>
            {test?.resultsLink !== undefined
              ? <a target="_blank" href={test?.resultsLink}>Click Here</a>
              : ""}
          </Card.Body>
        </Card> : <></>}

      <Card>
        <Card.Body>
          <Card.Title>Lab Details</Card.Title>

          {test?.labSelected?.name !== undefined
            ? test.labSelected.name
            : "Not Selected"}
          {test?.labSelected?.address === undefined ? <></> : <br />}
          {test?.labSelected?.address !== undefined
            ? test?.labSelected?.address
            : ""}
          {test?.labSelected?.turnaroundTime === undefined ? <></> : <br />}
          {test?.labSelected?.turnaroundTime !== undefined
            ? "Turnaround: " + test?.labSelected?.turnaroundTime + " day(s)"
            : ""}
        </Card.Body>
      </Card>

      <Card>
        <Card.Body>
          <Card.Title>Status</Card.Title>
          {test?.status}

          <Card.Text></Card.Text>
        </Card.Body>
      </Card>

      <Button block onClick={handleBack} variant="primary">
        Go Back
      </Button>
    </article>
  );
};

export default PatientRequestDetails;
