import React, { useState, useEffect, useContext } from "react";
import Header from "../../context/Header";
import { AuthContext } from "../../context/Auth.js";
import app from "../../context/base.js";
import { Card, Button, Form, Table, ProgressBar } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const PatientViewAllRequests = ({ component: RouteComponent, ...rest }) => {
  const [tests, setTests] = useState([]);

   const history = useHistory();
   
   var { currentUser } = useContext(AuthContext);

  useEffect(() => {
    var myTests = [];

    //currentUser.uid
    app
      .firestore()
       .collection("tests")
       .where("user", "==", currentUser.uid)
      .get()
      .then((snapshot) => {
        var i = 1;
        snapshot.forEach((doc) => {
          var data = doc.data();
          data.id = doc.id;
          data.num = i++;
          myTests.push(data);
        });
        setTests(myTests);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentUser.uid]);

  function tableRows(test) {
    return (
      <td>
        {test.labSelected?.name !== undefined
          ? test.labSelected.name
          : "Not Selected"}
        {test.labSelected?.address === undefined ? <></> : <br />}
        {test.labSelected?.address !== undefined ? test.labSelected?.address : ""}
        {test.labSelected?.turnaroundTime === undefined ? <></> : <br />} 
        {test.labSelected?.turnaroundTime !== undefined
          ? "Turnaround: " + test.labSelected?.turnaroundTime + " day(s)"
          : ""}
      </td>
    );
  }

  function progressBar(test) {
    var progress = 0;

    if (test.status === "Pending Lab Selection") progress = 10;
    else if (test.status === "Pending Payment") progress = 20;
    else if (test.status === "Pending Physician Review") progress = 40;
    else if (test.status === "Physician Accepted") progress = 60;
    else if (test.status === "Routed to Lab") progress = 80;
    else if (test.status === "Report Send") { progress = 100; test.status="Test completed, report available"}

    return <ProgressBar now={progress} srOnly />;
  }

  function openRequest(test) {
    if (test.status === "Pending Lab Selection") {
      history.push({
        pathname: "/p-passenger-select-lab",
        state: { detail: test },
      });
    } else if (test.status === "Pending Payment") {
      history.push({
        pathname: "/p-passenger-payment-process",
        state: { detail: test },
      });
    }
  }

  function viewRequest(test) {
    history.push({
        pathname: "/p-passenger-request-details",
        state: { detail: test },
      });

  }

  function handleGoNextPage() {}

  return (
    <article>
      <Header />

      <Card>
        <Card.Body>
          <Card.Title>My History</Card.Title>
          <Form onSubmit={handleGoNextPage}>
            <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Lab</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {tests.map((test) => {
                  return (
                    <tr key={test.num}>
                      <td>{test.num}</td>
                      <td>{test.passengers[0].name}</td>
                      {tableRows(test)}
                      <td>
                        {progressBar(test)}
                     
                        {test.status}
                        <br/><br/>
                      </td>
                      <td>
                        {test.status === "Pending Lab Selection" ||
                        test.status === "Pending Payment" ? (
                          <Button
                            onClick={() => openRequest(test)}
                            variant="primary"
                          >
                            Open
                          </Button>
                        ) : (
                          <Button
                            onClick={() => viewRequest(test)}
                            variant="success"
                          >
                            View
                          </Button>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <Card.Text></Card.Text>
          </Form>
          <Card.Text></Card.Text>
        </Card.Body>
      </Card>
    </article>
  );
};

export default PatientViewAllRequests;
