import React, { useState, useEffect } from "react";
import Header from "../../context/Header";
import { Card, Button, Form } from "react-bootstrap";
import app from "../../context/base.js";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { useHistory } from "react-router-dom";

const AdminUnauthResultsComplete = ({ component: RouteComponent, ...rest }) => {
  const history = useHistory();
  const [unAuthTests, setUnAuthTests] = useState([]);
  const [selectedTest, setSelectedTest] = useState({});

  const columns = [
    {
      dataField: "firstName",
      text: "First Name",
      filter: textFilter(),
    },
    {
      dataField: "lastName",
      text: "Last Name",
      filter: textFilter(),
    },
    {
      dataField: "typeOfTest",
      text: "Type of Test",
      filter: textFilter(),
    },
    {
      dataField: "dateOfTest",
      text: "Date of Test",
      filter: textFilter(),
    },
    {
      dataField: "dob",
      text: "Date of Birth",
      filter: textFilter(),
    },
    {
      dataField: "resultOutcome",
      text: "Result",
      filter: textFilter(),
    },
    {
      dataField: "email",
      text: "Email",
      filter: textFilter(),
    },
    {
      dataField: "employerEmail",
      text: "Employer Email",
      filter: textFilter(),
    },
    {
      dataField: "resultsLink",
      text: "Results",
      formatter: (val) => (val !== "" ? <a href={val}>Click Here</a> : <></>),
    },
    {
      dataField: "follow",
      text: "Action",
      formatter: linkFollow,
      //sort: true
    },
  ];

  function linkFollow(cell, row, rowIndex, formatExtraData) {
    setSelectedTest(row);
    return <Button onClick={() => updateSpecificResults(row)}>Details</Button>;
  }
  /*
  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }*/

  useEffect(() => {
    console.log("*");
    var list = [];
    app
      .firestore()
      .collection("unAuthTests")
      .where("status", "==", "Results Available")
      .get()
      .then((snapshot) => {
        var i = 1;
        snapshot.forEach((doc) => {
          var data = doc.data();
          data.id = doc.id;
          data.num = i++;
          list.push(data);
        });
        setUnAuthTests(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  /*
  const selectRow = {
    mode: "radio",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        console.log(row);
        setSelectedTest(row);
      } else {
        setSelectedTest({});
      }
    },
  };*/

  async function updateSpecificResults(row) {
    history.push({
      pathname: "/a-admin-unauth-results-update",
      state: { detail: row },
    });
  }
  /*
  async function updateResults() {
    if (isEmpty(selectedTest)) {
      alert("No Test Selected");
      return;
    }

    history.push({
      pathname: "/a-admin-unauth-results-update",
      state: { detail: selectedTest },
    });
  }*/

  return (
    <article>
      <Header />
      <Card>
        <Card.Body>
          <Card.Title>Completed Tests ({ unAuthTests.length })</Card.Title>

          <Form>
            <BootstrapTable
              keyField="id"
              //selectRow={selectRow}
              data={unAuthTests}
              columns={columns}
              filter={filterFactory()}
              hover
            />
          </Form>
          <Card.Text></Card.Text>
        </Card.Body>
      </Card>
    </article>
  );
};

export default AdminUnauthResultsComplete;
