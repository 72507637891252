import React, { useState, useEffect, useContext } from "react";
import Header from "../../context/Header";
import app from "../../context/base.js";
import { useHistory } from "react-router-dom";
import { Card, Button} from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { AuthContext } from "../../context/Auth.js";

const AdminRequestList = ({ component: RouteComponent, ...rest }) => {
   const [tests, setTests] = useState([]);
   const [testSelected, setTestSelected] = useState({});

   const history = useHistory();
   
   var { currentUser } = useContext(AuthContext);


   const columns = [
    {
      dataField: "passengers[0].name",
      text: "Name",
      filter: textFilter(),
    },
    {
      dataField: "status",
       text: "Status",
      filter: textFilter(),
    },
    {
      dataField: "id",
      text: "ID",
      filter: textFilter(),
    },
    {
      dataField: "numPassengers",
      text: "# Passengers",
     },
    {
      dataField: "createdOn",
      text: "Created",
      formatter: (cell, row) => new Date(cell).toLocaleString()
    },
  ];

  useEffect(() => {
    var myTests = [];

    //currentUser.uid
    app
      .firestore()
       .collection("tests")
      .get()
      .then((snapshot) => {
        var i = 1;
        snapshot.forEach((doc) => {
          var data = doc.data();
          data.id = doc.id;
          data.num = i++;
          myTests.push(data);
        });
        setTests(myTests);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentUser.uid, tests]);

   
   const selectRow = {
    mode: "radio",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setTestSelected(row);
      } else {
        setTestSelected();
      }
    },
  };

   function handleGoNextPage() {
      history.push({
        pathname: "/a-admin-request-detail",
        state: { detail: testSelected },
      });
   }
   

  return (
    <article>
      <Header />

      <Card>
        <Card.Body>
              <Card.Title>
                 All Requests
                  
                  
              </Card.Title>

              <Button
              block
              onClick={handleGoNextPage}
              variant="primary"
            >
              Go to next step
            </Button>
              
              <BootstrapTable
              keyField="id"
              selectRow={selectRow}
              data={tests}
              columns={columns}
              filter={filterFactory()}
              hover
              />
              
           
            <Card.Text></Card.Text>
         <Button
              block
              onClick={handleGoNextPage}
              variant="primary"
            >
              Go to next step
            </Button>
        </Card.Body>
      </Card>
    </article>
  );
};

export default AdminRequestList;
