import React, { useState, useEffect } from "react";
import Header from "../../context/Header";
import app from "../../context/base.js";
import { Card, Form } from "react-bootstrap";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

const AdminPhysicianList = ({ component: RouteComponent, ...rest }) => {
  const [physicians, setPhysicians] = useState([]);

  const columns = [
    {
      dataField: "name",
      text: "Physician Name",
      filter: textFilter(),
    },
    {
      dataField: "uid",
      text: "UID",
      filter: textFilter(),
    },
    {
      dataField: "address",
      text: "Address",
      filter: textFilter(),
    },
    {
      dataField: "phone",
      text: "Phone",
      filter: textFilter(),
    },
    {
      dataField: "fax",
      text: "Fax",
    },
    {
      dataField: "physicianNPI",
      text: "NPI",
      filter: textFilter(),
    },
    {
      dataField: "signature",
      text: "Signature",
      formatter: val => <img src={val} style={{ width: '100%', maxWidth: 60 }} />
    },
  ];

  useEffect(() => {
    var list = [];
    app
      .firestore()
       .collection("users")
       .where("type", "==", "Physician")
      .get()
      .then((snapshot) => {
        var i = 1;
        snapshot.forEach((doc) => {
          var data = doc.data();
          data.id = doc.id;
          data.num = i++;
          list.push(data);
        });
        setPhysicians(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [physicians]);

  const selectRow = {
    mode: "radio",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        //setLabSelected(row);
      } else {
        //setLabSelected();
      }
    },
  };
   
   function handleGoNextPage(event) {

   }

  return (
    <article>
      <Header />

      <Card>
        <Card.Body>
          <Card.Title>Physicians List</Card.Title>

          <Form onSubmit={handleGoNextPage}>

            <BootstrapTable
              keyField="id"
              selectRow={selectRow}
              data={physicians}
              columns={columns}
              filter={filterFactory()}
              hover
            />
          </Form>
          <Card.Text></Card.Text>
        </Card.Body>
      </Card>
    </article>
  );
};

export default AdminPhysicianList;
