import React, { useState, useContext } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
//import axios from "axios";
import Header from "../../context/Header";
import app from "../../context/base.js";
import { AuthContext } from "../../context/Auth.js";
import { useHistory } from "react-router-dom";
import { Card, Button, Form, Accordion } from "react-bootstrap";
import { occupations } from "../../context/data/occupations.js";
import { countries } from "../../context/data/country.js";

const PatientPassengerData = ({ component: RouteComponent, ...rest }) => {
  const [name, setName] = useState("");
  const [address, setAddress] = useState(null);
  const [dob, setDOB] = useState("");
  const [purpose, setPurpose] = useState("");
  const [schoolName, setSchoolName] = useState("");
  const [travelCountry, setTravelCountry] = useState("");
  const [travelRegion, setTravelRegion] = useState("");
  const [occupation, setOccupation] = useState("");
  const [travelDate, setTravelDate] = useState("");
  const [passengers, setPassengers] = useState([]);
  //const [purposeTags, setPurposeTags] = useState(<div></div>);

  var { currentUser } = useContext(AuthContext);
  const history = useHistory();

  function validateAddPassenger() {
    return (
      purpose!=="" && name.length > 0 &&
      dob.length > 0 &&
      (!isEmpty(address) || passengers.length > 0)
    );
  }

  function validateSubmit() {
    return passengers.length > 0;
  }

  function handleDeletePassenger(event) {
    console.log("Deleting index " + event.target.value.toString());
    var tempPassengers = [...passengers];
    tempPassengers.splice(event.target.value, 1);
    setPassengers(tempPassengers);
  }

  function handleAddPassenger(event) {
    event.preventDefault();

    var testPurpose = "u07.1 COVID-19";

    if (purpose === "Travel: To meet requirements from airlines or destinations") {
      testPurpose = "z41.8 Travel Medicine";
    }

    if (!isEmpty(address)) {
      geocodeByPlaceId(address.value.place_id)
        .then((results) => {
          const myAddress = {
            address: results[0].formatted_address,
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          };
          setPassengers([
            ...passengers,
            {
              name: name,
              dob: dob,
              passengerID: passengers.length,
              address: myAddress,
              testPurpose: testPurpose,
              purpose: purpose,
              schoolName: schoolName,
              travelCountry: travelCountry,
              travelRegion: travelRegion,
              occupation: occupation,
              travelDate: travelDate,
            },
          ]);
          setName("");
          setAddress(null);
          setDOB("");
          setPurpose("");
          setSchoolName("");
          setTravelCountry("");
          setTravelRegion("");
          setOccupation("");
        })
        .catch((error) => console.error(error));
    } else if (passengers.length > 0) {
      setPassengers([
        ...passengers,
        {
          name: name,
          dob: dob,
          address: passengers[0].address,
          purpose: purpose,
          testPurpose: testPurpose,
          schoolName: schoolName,
          travelCountry: travelCountry,
          travelRegion: travelRegion,
          occupation: occupation,
          travelDate: travelDate,
        },
      ]);
      setName("");
      setAddress(null);
      setDOB("");
      setPurpose("");
      setSchoolName("");
      setTravelCountry("");
      setTravelRegion("");
      setOccupation("");
    }
  }

  async function handleGoNextPage(event) {
    event.preventDefault();
    var data = {
      passengers: Object.assign({}, passengers),
      user: currentUser.uid,
      status: "Pending Lab Selection",
      email: currentUser.email,
      numPassengers: passengers.length,
      createdOn: Date.now(),
    };

    const testData = await app
      .firestore()
      .collection("tests")
      .add({
        passengers: Object.assign({}, passengers),
        user: currentUser.uid,
        status: "Pending Lab Selection",
        email: currentUser.email,
        numPassengers: passengers.length,
        createdOn: Date.now(),
      });
    data = { ...data, id: testData.id };
    history.push({
      pathname: "/p-passenger-select-lab",
      state: { detail: data },
    });
  }

  function isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  var passengerAccordian = passengers.map(function (passenger, i) {
    console.log(passenger.name);
    console.log(i);
    return (
      <Card key={passenger.name + passenger.dob}>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey={i.toString()}>
            {passenger.name}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={i.toString()}>
          <Card.Body>
            <Card.Text>Name: {passenger.name}</Card.Text>
            <Card.Text>Home Address: {passenger.address.address}</Card.Text>
            <Button
              block
              value={i}
              onClick={handleDeletePassenger}
              variant="danger"
            >
              Remove Passenger
            </Button>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  });

  var purposeTags;
  if (purpose === "Travel: To meet requirements from airlines or destinations") {
    purposeTags = (
      <>
        <Form.Group>
          <Form.Label>Country</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => setTravelCountry(e.target.value)}
          >
            {countries.map((element, i) => {
              return (
                <option key={element.countryName}>{element.countryName}</option>
              );
            })}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>City</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => setTravelRegion(e.target.value)}
          >
            {travelCountry !== "" ? (
              countries
                .filter(
                  (element, i) => element.countryName === travelCountry
                )[0]
                .regions.map(function (myRegions, j) {
                  return <option key={myRegions.name}>{myRegions.name}</option>;
                })
            ) : (
              <></>
            )}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Travelling To</Form.Label>
          <Form.Control
            type="date"
            placeholder="Travelling to"
            value={travelDate}
            onChange={(e) => setTravelDate(e.target.value)}
          />
        </Form.Group>
      </>
    );
  } else if (purpose === "School: For clearance to return back to school") {
    purposeTags = (
      <Form.Group>
        <Form.Label>School Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="School Name"
          value={schoolName}
          onChange={(e) => setSchoolName(e.target.value)}
        />
      </Form.Group>
    );
  } else if (purpose === "Work: To return back to the office safely") {
    purposeTags = (
      <Form.Group>
        <Form.Label>Occupation</Form.Label>
        <Form.Control
          as="select"
          onChange={(e) => setOccupation(e.target.value)}
        >
          {occupations.map((element) => (
            <option key={element}>{element}</option>
          ))}
        </Form.Control>
      </Form.Group>
    );
  } else {
    purposeTags = <div></div>;
  }

  return (
    <article>
      <Header />
      <Accordion>{passengerAccordian}</Accordion>
    
      <Card>
        <Card.Body>
          <Card.Title>Add Passenger</Card.Title>
          <Form onSubmit={handleAddPassenger}>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Home Address</Form.Label>
              <GooglePlacesAutocomplete
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ["us", "ca"],
                  },
                }}
                id="addressField"
                selectProps={{
                  address,
                  onChange: setAddress,
                }}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>DOB</Form.Label>
              <Form.Control
                type="date"
                placeholder="DOB"
                value={dob}
                onChange={(e) => setDOB(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Purpose</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => setPurpose(e.target.value)}
              >
                <option></option>
                <option>Medical: Get clearance prior to procedures (surgeries, treatments)</option>
                <option>School: For clearance to return back to school</option>
                <option>Travel: To meet requirements from airlines or destinations</option>
                <option>Work: To return back to the office safely</option>
              </Form.Control>
            </Form.Group>

            <div>{purposeTags}</div>

            <div style={{ display: "flex" }}>
              <Button
                style={{ marginLeft: "auto" }}
                disabled={!validateAddPassenger()}
                type="submit"
              >
                Add Passenger
              </Button>
            </div>
          </Form>
          <Card.Text></Card.Text>

          <Button
            block
            disabled={!validateSubmit()}
            onClick={handleGoNextPage}
            variant="primary"
          >
            Go to next step
          </Button>
        </Card.Body>
      </Card>
    </article>
  );
};

export default PatientPassengerData;

/*  function handleCheckFlightRequirement(event) {
    event.preventDefault();

    var destinationValue = this.destination?.value ?? "";

    if (destinationValue === "") {
      alert("Destination is empty.");
      return;
    }

    destinationValue = this.capitalizeFirstLetter(destinationValue);

    var config = {
      headers: {
        "X-Access-Token": "cdf7d13c-9090-4e15-8d52-e5e441be3b05",
      },
    };

    var url =
      "https://api.traveladviceapi.com/search/chicago:" + destinationValue;

    axios
      .get(url, config, { timeout: 100000 })
      .then(async (data) => {
        if (data.status === 200) {
          this.setState({
            data: data,
            showModal: true,
          });

        } else {
          alert(
            "We couldn't find data on your destination city. Please try again."
          );
        }
      })
      .catch((err) => {
        alert(
          "We couldn't find data on your destination city. Please try again."
        );
      });
  }
  */
