

export function GetCurrentDate() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  return mm + "/" + dd + "/" + yyyy;
}

export function CreateChatUID(uid1, uid2) {
  if (uid1 < uid2) {
    return uid1 + uid2;
  } else {
    return uid2 + uid1;
  }
}

export function ConvertTimestampToTime(unix_timestamp) {
  var date = new Date(unix_timestamp * 1000);
  var hours = "0" + date.getHours();
  var minutes = "0" + date.getMinutes();
  var seconds = "0" + date.getSeconds();

  var formattedTime =
    hours.substr(-2) + ":" + minutes.substr(-2) + ":" + seconds.substr(-2);
  return formattedTime;
}

export function TodaysDate() {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  return today;
}

export function IsEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

export function ConvertTimestampToPrettyDate(unix_timestamp) {
  if (
    unix_timestamp === undefined ||
    unix_timestamp === null ||
    unix_timestamp === 0
  ) {
    return "";
  } else {
    return new Date(unix_timestamp).toLocaleString();
  }
}

export function ConvertDayToTimestamp(date) {
  var myDate = date;
  myDate = myDate.split("-");
  var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);
  return newDate.getTime();
}

export function ConvertTimestampToDate(unix_timestamp) {
  if (
    unix_timestamp === undefined ||
    unix_timestamp === null ||
    unix_timestamp === 0
  ) {
    return "";
  }

  var date = new Date(unix_timestamp);

  var month = "0" + (date.getMonth() + 1);
  var day = "0" + date.getDate();

  var hours = "0" + date.getHours();
  var minutes = "0" + date.getMinutes();
  var seconds = "0" + date.getSeconds();
  var start =
    date.getFullYear() +
    "-" +
    month.substr(-2) +
    "-" +
    day.substr(-2) +
    "T" +
    hours.substr(-2) +
    ":" +
    minutes.substr(-2) +
    ":" +
    seconds.substr(-2);

  return start;
}

export function GetDaysInMonth() {
  var today = new Date();
  var dd = 1;
  var mm = today.getMonth(); //January is 0!
  var yyyy = today.getFullYear();
  var date1 = new Date(yyyy, mm, dd);
  var days = [];
  while (date1.getMonth() === mm) {
    var temp = date1;
    var dd1 = String(temp.getDate()).padStart(2, "0");
    var mm1 = String(temp.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy1 = temp.getFullYear();
    var t = yyyy1 + "-" + mm1 + "-" + dd1;
    
    days.push({ date: t, counter: 0 });
    date1.setDate(date1.getDate() + 1);
  }
  return days;
}

export function GetDayTimestampRange(unix_timestamp) {
  var start = new Date(unix_timestamp);

  start.setUTCHours(0, 0, 0, 0);

  var end = new Date(unix_timestamp);
  end.setUTCHours(23, 59, 59, 999);
  return { start: start.getTime(), end: end.getTime() };
}

export function ConvertTimestampToDay(unix_timestamp) {
  var date = new Date(unix_timestamp);

  var month = "0" + (date.getMonth() + 1);
  var day = "0" + date.getDate();



  var start =
    date.getFullYear() + "-" + month.substr(-2) + "-" + day.substr(-2);

  return start;
}

export function HelloTester() {}
