import React, { useContext } from "react";
import { CSVReader } from "react-papaparse";
import { Card } from "react-bootstrap";
import Header from "../../context/Header";
import { AuthContext } from "../../context/Auth.js";
import app from "../../context/base.js";
import { useHistory } from "react-router-dom";
import axios from "axios";

const AdminAuauthCSV = ({ component: RouteComponent, ...rest }) => {
  var { currentUser } = useContext(AuthContext);
  const history = useHistory();

  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  async function handleOnDrop(data) {
    console.log(data.length);
    for (var i = 1; i < data.length; i++) {
      var arr = data[i].data;
      var email = arr[0];
      var firstName = arr[1];
      var lastName = arr[2];
      var dateOfTest = arr[3];
      var dob = arr[4];
      var typeOfTest = arr[5];
      var employerEmail = arr[6];
      var resultsLink = "";
      var status = "Results Pending";

      if(employerEmail === undefined || employerEmail === null) {
        employerEmail = "";
      }

      console.log(dob.length !== 10);
      console.log(dateOfTest.length !== 10);
      console.log(!validateEmail(email) );
      console.log(firstName === "");
      console.log(lastName === "");
      console.log(typeOfTest === "");

      if (
        dob.length !== 10 ||
        dateOfTest.length !== 10 ||
        !validateEmail(email) ||
        firstName === "" ||
        lastName === "" ||
        typeOfTest === ""
      ) {
        console.log("error");
        alert("FAILED on row " + i + ". Nothing after that row was uploaded.");
        return;
      } else {
        await app.firestore().collection("unAuthTests").add(
          {
            email: email,
            firstName: firstName,
            lastName: lastName,
            dob: dob,
            addedByUID: currentUser.uid,
            addedByEmail: currentUser.email,
            createdOn: Date.now(),
            dateOfTest: dateOfTest,
            resultsLink: resultsLink,
            status: status,
            employerEmail: employerEmail,
            typeOfTest: typeOfTest,
          },
          { merge: true }
        );
        try {
          await axios
            .post(
              "https://us-central1-pcr-test-portal.cloudfunctions.net/sendEmail",
              {
                sendTo: email,
                typeOfTest: typeOfTest,
                firstName: firstName,
                reason: status,
              }
            )
            .then(async (serverResults) => {
              console.log(serverResults);
            });
        } catch (error) {
          console.log(error);
        }
      }
    }
    alert("Successfully uploaded");
    history.push("/a-admin-unauth-results-pending");
  }

  function handleOnError(err, file, inputElem, reason) {
    console.log(err);
  }

  function handleOnRemoveFile(data) {}

  return (
    <article>
      <Header />
      <Card>
        <Card.Body>
          <Card.Title>Upload CSV File</Card.Title>
          <CSVReader
            onDrop={handleOnDrop}
            onError={handleOnError}
            noClick
            addRemoveButton
            onRemoveFile={handleOnRemoveFile}
          >
            <span>Drop CSV file here to upload.</span>
          </CSVReader>
        </Card.Body>
      </Card>
    </article>
  );
};

export default AdminAuauthCSV;
