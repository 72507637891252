import React, {  } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";

import { AuthProvider } from "./context/Auth";
import PrivateRoute from "./context/PrivateRoute";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Dashboard from "./pages/dashboard/Dashboard";
import PatientPassengerData from "./pages/patient/PatientPassengerData";
import PatientSelectLab from "./pages/patient/PatientSelectLab";
import PatientPaymentProcess from "./pages/patient/PatientPaymentProcess";
import PatientViewAllRequests from "./pages/patient/PatientViewAllRequests";
import PhysicianOpenRequests from "./pages/physician/PhysicianOpenRequests";
import PhysicianMyRequests from "./pages/physician/PhysicianMyRequests";
import PhysicianRequestDetails from "./pages/physician/PhysicianRequestDetails";
import AdminLabList from "./pages/admin/AdminLabList";
import AdminAddEmployer from "./pages/admin/AdminAddEmployer";
import AdminPhysicianList from "./pages/admin/AdminPhysicianList";
import AdminCustomLabList from "./pages/admin/AdminCustomLabList";
import AdminRequestList from "./pages/admin/AdminRequestList";
import AdminRequestDetails from "./pages/admin/AdminRequestDetails";
import PatientRequestDetails from "./pages/patient/PatientRequestDetails";
import AdminUnauthResultsAdd from "./pages/admin/AdminAuauthResultsAdd";
import AdminUnauthResultsUpdate from "./pages/admin/AdminAuauthResultsUpdate";
import CheckResults from "./pages/preauth/CheckResults";
import AdminAuauthCSV from "./pages/admin/AdminAuauthCSV";
import AdminVenueQR from "./pages/admin/AdminVenueQR";
import AdminUnauthResultsComplete from "./pages/admin/AdminAuauthResultsComplete";
import AdminUnauthResultsPending from "./pages/admin/AdminAuauthResultsPending";
import EmployerAllTests from "./pages/employer/EmployerAllTests";
import EmployerDashboard from "./pages/employer/EmployerDashboard";
import ForgotPassword from "./pages/auth/ForgotPassword";
import AdminViewAllEmployer from "./pages/admin/AdminViewAllEmployer";
import VenueAddRequests from "./pages/venue/VenueAddRequest";
import AdminVenueListPending from "./pages/admin/AdminVenueListPending";
import AdminVenueListComplete from "./pages/admin/AdminVenueListComplete";
import AdminVenueDetails from "./pages/admin/AdminVenueDetails";

function App() {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>

          <Route exact path="/register">
            <Register />
          </Route>

          <Route exact path="/forgot-password">
            <ForgotPassword />
          </Route>

          <Route exact path="/check-results">
            <CheckResults />
          </Route>

          <Route exact path="/check-in">
            <VenueAddRequests />
          </Route>

          <PrivateRoute exact path="/dashboard" component={Dashboard} />

          <PrivateRoute
            exact
            path="/p-passenger-data"
            component={PatientPassengerData}
          />
          <PrivateRoute
            exact
            path="/p-passenger-select-lab"
            component={PatientSelectLab}
          />
          <PrivateRoute
            exact
            path="/p-passenger-payment-process"
            component={PatientPaymentProcess}
          />
          <PrivateRoute
            exact
            path="/p-passenger-view-all-requests"
            component={PatientViewAllRequests}
          />
           <PrivateRoute
            exact
            path="/p-passenger-request-details"
            component={PatientRequestDetails}
          />

      
          <PrivateRoute
            exact
            path="/p-physician-view-all-requests"
            component={PhysicianOpenRequests}
          />
          <PrivateRoute
            exact
            path="/p-physician-view-my-requests"
            component={PhysicianMyRequests}
          />
          <PrivateRoute
            exact
            path="/p-physician-request-details"
            component={PhysicianRequestDetails}
          />


          
          

          <PrivateRoute
            exact
            path="/a-admin-add-employer"
            component={AdminAddEmployer}
          />


          <PrivateRoute
            exact
            path="/a-admin-labs"
            component={AdminLabList}
          />
          <PrivateRoute
            exact
            path="/a-admin-physician"
            component={AdminPhysicianList}
          />
          <PrivateRoute
            exact
            path="/a-admin-custom-labs"
            component={AdminCustomLabList}
          />
          <PrivateRoute
            exact
            path="/a-admin-request-list"
            component={AdminRequestList}
          />
          <PrivateRoute
            exact
            path="/a-admin-request-detail"
            component={AdminRequestDetails}
          />
          <PrivateRoute
            exact
            path="/a-admin-unauth-results-complete"
            component={AdminUnauthResultsComplete}
          />
          <PrivateRoute
            exact
            path="/a-admin-unauth-results-pending"
            component={AdminUnauthResultsPending}
          />
          <PrivateRoute
            exact
            path="/a-admin-unauth-results-add"
            component={AdminUnauthResultsAdd}
          />
          <PrivateRoute
            exact
            path="/a-admin-unauth-results-update"
            component={AdminUnauthResultsUpdate}
          />
           <PrivateRoute
            exact
            path="/a-admin-unauth-csv"
            component={AdminAuauthCSV}
          />

          <PrivateRoute
            exact
            path="/a-admin-all-employer"
            component={AdminViewAllEmployer}
          />


          <PrivateRoute
            exact
            path="/a-venue-qr"
            component={AdminVenueQR}
          />
          <PrivateRoute
            exact
            path="/a-venue-pending"
            component={AdminVenueListPending}
          />
          <PrivateRoute
            exact
            path="/a-venue-complete"
            component={AdminVenueListComplete}
          />
          <PrivateRoute
            exact
            path="/a-venue-details"
            component={AdminVenueDetails}
          />


          


          <PrivateRoute
            exact
            path="/e-dashboard"
            component={EmployerDashboard}
          />
          <PrivateRoute
            exact
            path="/e-all-tests"
            component={EmployerAllTests}
          />

         

        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;