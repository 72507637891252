import React, { useState, useContext } from "react";
import Header from "../../context/Header";
import {
  Card,
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth.js";
import axios from "axios";

import app from "../../context/base.js";

const AdminUnauthResultsAdd = ({ component: RouteComponent, ...rest }) => {
  var { currentUser } = useContext(AuthContext);

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfTest, setDateOfTest] = useState("");
  const [dob, setDOB] = useState("");
  const [resultOutcome, setResultOutcome] = useState("");
  //const [address, setAddress] = useState("");
  //const [state, setState] = useState("");
  //const [city, setCity] = useState("");
  //const [country, setCountry] = useState("");
  //const [zip, setZip] = useState("");
  const [typeOfTest, setTypeOfTest] = useState("");

  //const [status, setStatus] = useState("");
  const [employerEmail, setEmployerEmail] = useState("");
  const [resultsLink, setResultsLink] = useState("");


  async function updateResults() {
    var status = "Results Available";

    if (resultsLink === "") {
      status = "Results Pending";
    } else {
      status = "Results Available";
      
    }

    if (typeOfTest ==="" || email === "" || firstName === "" || lastName === "" || dateOfTest==="" || dob==="") {
      alert("Error: All fields are mandatory");
      return;
    }

    


    await app.firestore().collection("unAuthTests").add(
      {
        email: email,
        firstName: firstName,
        lastName: lastName,
        dob: dob,
        addedByUID: currentUser.uid,
        addedByEmail: currentUser.email,
        createdOn: Date.now(),
        resultOutcome: resultOutcome,
        //address: address,
        dateOfTest: dateOfTest,
        employerEmail: employerEmail,
        //city: city,
        //country: country,
        //zip: zip,
        resultsLink: resultsLink,
        //state: state,
        status: status,
        typeOfTest: typeOfTest,
      },
      { merge: true }
    );

    try {
      await axios
        .post(
          "https://us-central1-pcr-test-portal.cloudfunctions.net/sendEmail",
          {
            sendTo: email,
            typeOfTest: typeOfTest,
            firstName: firstName,
            reason: status,
          }
        )
        .then(async (serverResults) => {
          //console.log(serverResults);
        });
    } catch (error) {
      console.log(error);
    }




    alert("Successfully saved");
    history.push("/dashboard");
  }

  return (
    <article>
      <Header />

      <Card>
        <Card.Body>
          <Card.Title>Add Result</Card.Title>

          <FormGroup>
            <FormLabel>Email</FormLabel>
            <FormControl
              autoFocus
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>First Name</FormLabel>
            <FormControl
              
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Last Name</FormLabel>
            <FormControl
              
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Date of Test</FormLabel>
            <FormControl
              
              type="date"
              value={dateOfTest}
              onChange={(e) => setDateOfTest(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Date of Birth</FormLabel>
            <FormControl
              
              type="date"
              value={dob}
              onChange={(e) => setDOB(e.target.value)}
            />
          </FormGroup>

          <Form.Group>
            <Form.Label>Type of Test: </Form.Label>
            <Form.Control
              as="select"
              value={typeOfTest}
              onChange={(e) => setTypeOfTest(e.target.value)}
            >
              <option></option>
              <option>PCR swab</option>
              <option>Antibody</option>
              <option>Rapid Test</option>
              <option>Molecular</option>
            </Form.Control>
          </Form.Group>


          <FormGroup>
            <FormLabel>Employer Email</FormLabel>
            <FormControl
              
              type="text"
              value={employerEmail}
              onChange={(e) => setEmployerEmail(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Send Safely Results Link</FormLabel>
            <FormControl
              
              type="text"
              value={resultsLink}
              onChange={(e) => setResultsLink(e.target.value)}
            />
          </FormGroup>

           <Form.Group>
            <Form.Label>Result Outcome </Form.Label>
            <Form.Control
              as="select"
              value={resultOutcome}
              onChange={(e) => setResultOutcome(e.target.value)}
            >
              <option> </option>
              <option>Positive</option>
              <option>Negative</option>
              <option>Inconclusive</option>
            </Form.Control>
          </Form.Group>

          
        </Card.Body>
      </Card>

      <Button block onClick={updateResults} variant="primary">
        Submit
      </Button>
      <br/><br/>
    </article>
  );
};

export default AdminUnauthResultsAdd;

/*
<Form.Group>
            <Form.Label>Status: </Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option></option>
              <option>Results Pending</option>
              <option>Results Available</option>
            </Form.Control>
          </Form.Group>
          */