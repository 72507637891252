import React, { useState, useContext, useEffect } from "react";
import Header from "../../context/Header";
import { Card, Button, Form } from "react-bootstrap";
import app from "../../context/base.js";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth.js";

const EmployerAllTests = ({ component: RouteComponent, ...rest }) => {
   const history = useHistory();
   var { currentUser } = useContext(AuthContext);
  const [unAuthTests, setUnAuthTests] = useState([]);

  const columns = [
    {
      dataField: "firstName",
      text: "First Name",
      filter: textFilter(),
    },
    {
      dataField: "lastName",
      text: "Last Name",
      filter: textFilter(),
    },
    {
      dataField: "typeOfTest",
      text: "Type of Test",
      filter: textFilter(),
    },
    {
      dataField: "dateOfTest",
      text: "Date of Test",
      filter: textFilter(),
    },
    {
      dataField: "resultOutcome",
      text: "Result",
      filter: textFilter(),
    },
  ];


  useEffect(() => {
    console.log("*");
    var list = [];
    app
      .firestore()
      .collection("unAuthTests")
      //.where("employerEmail", "==", currentUser.email)
      .get()
      .then((snapshot) => {
        var i = 1;
        snapshot.forEach((doc) => {     
          console.log(doc.data());
          var data = doc.data();
          if (data?.employerEmail?.toLowerCase().trim() === currentUser.email.toLowerCase().trim()) {   
            data.id = doc.id;
            data.num = i++;
            list.push(data);
          }
        });
        setUnAuthTests(list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [currentUser.email]);
  /*
  const selectRow = {
    mode: "radio",
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        console.log(row);
        setSelectedTest(row);
      } else {
        setSelectedTest({});
      }
    },
  };*/

  async function updateSpecificResults(row) {
    history.push({
      pathname: "/a-admin-unauth-results-update",
      state: { detail: row },
    });
  }
  /*
  async function updateResults() {
    if (isEmpty(selectedTest)) {
      alert("No Test Selected");
      return;
    }

    history.push({
      pathname: "/a-admin-unauth-results-update",
      state: { detail: selectedTest },
    });
  }*/

  return (
    <article>
      <Header />
      <Card>
        <Card.Body>
          <Card.Title>Completed Tests ({ unAuthTests.length })</Card.Title>

          <Form>
            <BootstrapTable
              keyField="id"
              //selectRow={selectRow}
              data={unAuthTests}
              columns={columns}
              filter={filterFactory()}
              hover
            />
          </Form>
          <Card.Text></Card.Text>
        </Card.Body>
      </Card>
    </article>
  );
};

export default EmployerAllTests;
