import React, { useContext, useEffect, useState } from "react";
import Header from "../../context/Header";
import app from "../../context/base.js";
import { Card, Container, Row, Col } from "react-bootstrap";
import { AuthContext } from "../../context/Auth.js";
import { Chart } from "react-google-charts";
import { ConvertTimestampToDay, GetDaysInMonth } from "../../utilities/Utility";

const EmployerDashboard = ({ component: RouteComponent, ...rest }) => {
  var { currentUser } = useContext(AuthContext);
  const [resultsOutomeData, setResultsOutomeData] = useState([]);
  const [lineData, setLineData] = useState([]);
  const [employerName, setEmployerName] = useState("");

  function refreshNumberOfTestsBarChart() {
    setLineData([]);
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
    var lastDay = new Date(
      date.getFullYear(),
      date.getMonth() + 1,
      0
    ).getTime();

    var list1 = [];
    var tempList = GetDaysInMonth();

     var listForBarChart = [["day", "tests"]];
    app
      .firestore()
      .collection("unAuthTests")
      //.where("employerEmail", "==", currentUser.email)
      .where("createdOn", ">=", firstDay)
      .where("createdOn", "<=", lastDay)
      .get()
      .then((snapshot) => {
        var i = 1;
        snapshot.forEach((doc) => {
          var data = doc.data();
          if (data?.employerEmail?.toLowerCase().trim() === currentUser.email.toLowerCase().trim()) {
          
            data.id = doc.id;
            var start = ConvertTimestampToDay(doc.data().createdOn);
            data.start = start;

            tempList.map(function increment(r) {
              if (r.date === data.start) {
                return (r.counter++);
              } else {
                return r.counter;
              }
            });
            data.num = i++;
            list1.push(data);
          }
        });
        for (const i in tempList) {
          listForBarChart.push([tempList[i].date, tempList[i].counter]);
        }
         setLineData(listForBarChart);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function refreshResultsOutomePieChart() {
    var temp = [
      ["Type", "Metric"],
      ["positive", 0],
      ["negative", 0],
      ["pending", 0],
    ];

    app
      .firestore()
      .collection("unAuthTests")
      //.where("employerEmail", "==", currentUser.email)
      .get()
      .then((snapshot) => {
        var i = 1;
        snapshot.forEach((doc) => {
          var data = doc.data();
          if (data?.employerEmail?.toLowerCase().trim() === currentUser.email.toLowerCase().trim()) {
            data.id = doc.id;
            data.num = i++;
            if (data.resultOutcome === "Positive") {
              temp[1][1]++;
            } else if (data.resultOutcome === "Negative") {
              temp[2][1]++;
            } else {
              temp[3][1]++;
            }
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
    setResultsOutomeData(temp);
  }

  useEffect(() => {
    console.log("*");

    app
      .firestore()
      .collection("users")
      .where("uid", "==", currentUser.uid)
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          var data = doc.data();
          setEmployerName(data["employerName"]);
        });
      })
      .catch((err) => {
        console.log(err);
      });
    
    
    
    refreshNumberOfTestsBarChart();
    refreshResultsOutomePieChart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser.email]);

  return (
    <article>
      <Header />

      <Container>
        <Row>
          <h2>{employerName}</h2>
        </Row>
        <Row>
          <br />
        </Row>
        <h3>Metrics</h3>
        <Row>
          <Col xs={12}>
            {" "}
            <Card>
              <Card.Body>
                <Card.Title>Tests By Date</Card.Title>
                  <div className={"my-pretty-chart-container"}>
                    <Chart
                      chartType="Bar"
                      loader={<div>Loading Chart</div>}
                      data={lineData}
                      options={{
                        hAxis: {
                          title: "Day",
                        },
                        vAxis: {
                          title: "# Tests",
                        },
                      }}
                      width="100%"
                      height="400px"
                    />
                  </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <br />
        </Row>
        <Row>
          <Col xs={12}>
            {" "}
            <Card>
              <Card.Body>
                <Card.Title>Cases</Card.Title>
                  <div className="App">
                    <Chart
                      width={"100%"}
                      height={"400px"}
                      chartType="PieChart"
                      loader={<div>Loading Chart</div>}
                      data={resultsOutomeData}
                      rootProps={{ "data-testid": "1" }}
                    />
                  </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>&nbsp;</Row>
      </Container>
    </article>
  );
};

export default EmployerDashboard;
