import React, { useState, useEffect } from "react";
import Header from "../../context/Header";
import {
  Card,
  Button,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import app from "../../context/base.js";
import axios from "axios";

const AdminUnauthResultsUpdate = ({ component: RouteComponent, ...rest }) => {
  const history = useHistory();
  const [id, setID] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfTest, setDateOfTest] = useState("");
  const [dob, setDOB] = useState("");
  const [resultOutcome, setResultOutcome] = useState("");
  //const [address, setAddress] = useState("");
  //const [state, setState] = useState("");
  //const [city, setCity] = useState("");
  //const [country, setCountry] = useState("");
  //const [zip, setZip] = useState("");
  const [typeOfTest, setTypeOfTest] = useState("");
  const [employerEmail, setEmployerEmail] = useState("");

  //const [status, setStatus] = useState("");
  const [resultsLink, setResultsLink] = useState("");


  useEffect(() => {
    console.log("*");
    setEmail(history.location.state.detail.email);
    setFirstName(history.location.state.detail.firstName);
    setLastName(history.location.state.detail.lastName);
    setDateOfTest(history.location.state.detail.dateOfTest);
    setDOB(history.location.state.detail.dob);
    setResultOutcome(history.location.state.detail.resultOutcome ?? "");

    setEmployerEmail(history.location.state.detail.employerEmail ?? "");


    console.log(resultOutcome);
    //setAddress(history.location.state.detail.address);
    //setState(history.location.state.detail.state);
    //setCity(history.location.state.detail.city);
    //setCountry(history.location.state.detail.country);
    //setZip(history.location.state.detail.zip);
    setResultsLink(history.location.state.detail.resultsLink);
    //setStatus(history.location.state.detail.status);
    setID(history.location.state.detail.id);
    setTypeOfTest(history.location.state.detail.typeOfTest);
  }, [history.location.state.detail, history.location.state.detail.id]);


  async function updateResults() {

    var status = "Results Available";

    if (resultsLink === "") {
      status = "Results Pending";
    } else {
      status = "Results Available";
    }
     if (typeOfTest ==="" || email === "" || firstName === "" || lastName === "" || dateOfTest==="" || dob==="") {
      alert("Error: All fields are mandatory");
      return;
     }
    
    

    await app.firestore().collection("unAuthTests").doc(id).update(
      {
        email: email,
        firstName: firstName,
        lastName: lastName,
        dateOfTest: dateOfTest,
        dob: dob,
        resultOutcome: resultOutcome,
        //address: address,
        //city: city,
        //country: country,
        //zip: zip,
        employerEmail: employerEmail,
        resultsLink: resultsLink,
        //state: state,
        status: status,
        typeOfTest: typeOfTest,
      },
      { merge: true }
    );

    try {
      await axios
        .post(
          "https://us-central1-pcr-test-portal.cloudfunctions.net/sendEmail",
          {
            sendTo: email,
            typeOfTest: typeOfTest,
            firstName: firstName,
            reason: status,
          }
        )
        .then(async (serverResults) => {
          console.log(serverResults);
        });
    } catch (error) {
      console.log(error);
    }

    


    alert("Successfully saved");
    history.push("/a-admin-unauth-results-pending");
  }

  async function deleteTest() {
    console.log(id);
    await app.firestore().collection("unAuthTests").doc(id).delete();    
    history.push("/a-admin-unauth-results-pending");
  }

  return (
    <article>
      <Header />

      <Card>
        <Card.Body>
          <Card.Title>Add Result <Button className="float-right" onClick={deleteTest} variant="danger">Delete</Button></Card.Title>

          <FormGroup>
            <FormLabel>Email</FormLabel>
            <FormControl
              autoFocus
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>First Name</FormLabel>
            <FormControl
              autoFocus
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Last Name</FormLabel>
            <FormControl
              autoFocus
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Date of Test</FormLabel>
            <FormControl
              
              type="date"
              value={dateOfTest}
              onChange={(e) => setDateOfTest(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Date of Birth</FormLabel>
            <FormControl
              autoFocus
              type="date"
              value={dob}
              onChange={(e) => setDOB(e.target.value)}
            />
          </FormGroup>

          <Form.Group>
            <Form.Label>Type of Test </Form.Label>
            <Form.Control
              as="select"
              value={typeOfTest}
              onChange={(e) => setTypeOfTest(e.target.value)}
            >
              <option></option>
              <option>PCR swab</option>
              <option>Antibody</option>
              <option>Rapid Test</option>
              <option>Molecular</option>
            </Form.Control>
          </Form.Group>


          <FormGroup>
            <FormLabel>Employer Email</FormLabel>
            <FormControl
              
              type="text"
              value={employerEmail}
              onChange={(e) => setEmployerEmail(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <FormLabel>Send Safely Results Link</FormLabel>
            <FormControl
              autoFocus
              type="text"
              value={resultsLink}
              onChange={(e) => setResultsLink(e.target.value)}
            />
          </FormGroup>

          <Form.Group>
            <Form.Label>Result Outcome </Form.Label>
            <Form.Control
              as="select"
              value={resultOutcome}
              onChange={(e) => setResultOutcome(e.target.value)}
            >
              <option> </option>
              <option>Positive</option>
              <option>Negative</option>
              <option>Inconclusive</option>
            </Form.Control>
          </Form.Group>

          
        </Card.Body>
      </Card>

      <Button block onClick={updateResults} variant="primary">
        Submit
      </Button>
      <br/><br/>
    </article>
  );
};

export default AdminUnauthResultsUpdate;

/*
<Form.Group>
            <Form.Label>Status: </Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <option></option>
              <option>Results Pending</option>
              <option>Results Available</option>
            </Form.Control>
          </Form.Group>
*/
